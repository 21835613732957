<template>
  <dash-page  title="FAQ"
              icon="mdi-help-circle-outline">

    <v-sheet  class="wsRoundedHalf pa-6 mt-6" v-if="$store.state.auth.userType === 'superadmin'">
      <div class="d-flex justify-space-between align-center">
        <h3>FAQ {{ $t('Questions')}}</h3>
        <v-btn @click="addFaqItem"
               :color="wsDARK"
               class="noCaps"
               text >
          <v-icon small>mdi-plus</v-icon>
          {{ $t('AddQuesiton') }}</v-btn>
      </div>

      <v-simple-table dense class="mt-3">
        <tbody>
        <tr v-for="(item,i) in items" :key="i">
          <td>
            <h5>{{ i+1 }}. {{ item.name }}</h5>
          </td>
          <td class="text-no-wrap" width="10px">
            <v-chip small outlined :color="item.is_active ? 'green lighten-2' : 'grey'">
              <h5> {{ item.is_active ? $t('Active') : $t('NotActive') }}</h5>
            </v-chip>
          </td>
          <td class="text-no-wrap"  width="10px">
            <v-btn :to="localeLink('dashboard/faq/' + item.uuid)"
                   :color="wsATTENTION"
                   class="noCaps"
                   icon >
              <v-icon>mdi-pencil-circle</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

    </v-sheet>

  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "DashAdminFaq",

  data() {
    return {
      project : 'fine',
      items : []
    }
  },
  methods : {
    ...mapActions('faq', [
          'GET_FAQ_ITEMS_ADMIN',
          'ADD_FAQ_ITEM'
        ]
    ),

    async addFaqItem() {
      let data = {
        project : this.is_westudy ? 'westudy' : 'fine'
      }
      let result = await this.ADD_FAQ_ITEM(data)
      if ( !result ) { return }

      this.items.push(result)
    }

  },
  async mounted(){
    
    let result = await this.GET_FAQ_ITEMS_ADMIN(this.is_westudy ? 'westudy' : 'fine')
    if ( !result || result === true ) {
      return
    }
    this.items = result
  }
}
</script>

<style scoped>

</style>